import {createApp} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import App from './App.vue';
import Vant from 'vant';
import {showDialog} from 'vant';
import axios from 'axios';
import 'vant/lib/index.css';
import VueLuckyCanvas from '@lucky-canvas/vue';
// 定义路由
const routes = [
  {path: '/', component: () => import('@/views/HomeView.vue')},
  {path: '/to-do-list', component: () => import('@/views/ToDoList.vue')},
  {path: '/flower', component: () => import('@/views/FlowerList.vue')},
  {path: '/balance', component: () => import('@/views/BalanceView.vue')},
  {path: '/balancePrize', component: () => import('@/views/BalancePrize.vue')},
  {path: '/balanceTimes', component: () => import('@/views/BalanceTimes.vue')},
  {path: '/index', component: () => import('@/views/IndexView.vue')},
  {path: '/weightLossDiary',
    component: () => import('@/views/WeightLossDiary.vue')},
  // 其他路由...
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 创建 Vue 应用
const app = createApp(App);
app.use(VueLuckyCanvas);
app.use(Vant);
// 使用路由
app.use(router);

axios.interceptors.response.use((response) => {
  // 对响应数据做点什么
  const res = response.data;
  if (res.code === 200) {
    return res;
  }
  showDialog({type: 'error', message: res.message});
  return res;
}, function(error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

app.config.globalProperties.$http = axios;
app.config.globalProperties.$icons = [
  {
    icon: 'todo-list-o',
    title: '猪猪待办',
    path: '/to-do-list',
  },
  {
    icon: 'todo-list-o',
    title: '身体指标',
    path: '/index',
  },
  {
    icon: 'balance-o',
    title: '猪猪红包',
    path: '/balance',
  },
];
app.mount('#app');

