<template>
  <div style="background-color: #F7F8FA;height: 100%;overflow: auto" ref="body">
    <van-nav-bar :title="title" :fixed="true">
      <template #left v-if="$route.path !== '/'">
        <a style="color: #1989FA" @click="$router.go(-1)">{{ '返回' }}</a>
      </template>
      <template #right v-if="$route.path !== '/'">
        <a style="color: #1989FA" @click="$router.push('/')">{{ '首页' }}</a>
      </template>
    </van-nav-bar>
    <router-view/>
  </div>
</template>

<style>
body, html, #app {
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
<script>
export default {
  computed: {
    title() {
      for (let i = 0; i < this.$icons.length; i++) {
        if (this.$route.path === this.$icons[i].path) {
          return this.$icons[i].title;
        }
      }
      return '欢迎来到小仙女和猪猪的小窝';
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
